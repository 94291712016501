@media (min-width: 500px) {
  .title-pos {
    position: relative;
    top: 30vh;
    right: 50%;
    animation-name: floating;
    animation-duration: 8s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    color: white;
    z-index: -2;
  }
  .name-first {
    text-transform: uppercase;
    font-family: verdana;
    height: 1em;
    font-size: 6vw;
    font-weight: 700;
    color: #f5f5f5;
    text-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191,
      1px 4px 1px #919191, 1px 5px 1px #919191, 1px 6px 1px #919191,
      1px 7px 1px #919191, 1px 8px 1px #919191, 1px 9px 1px #919191,
      1px 10px 1px #919191, 1px 18px 6px rgba(16, 16, 16, 0.4),
      1px 22px 10px rgba(16, 16, 16, 0.2), 1px 25px 35px rgba(16, 16, 16, 0.2),
      1px 30px 60px rgba(16, 16, 16, 0.4);
  }
  .name-last {
    text-transform: uppercase;
    font-family: verdana;
    font-size: 6vw;
    font-weight: 700;
    color: #f5f5f5;
    text-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191,
      1px 4px 1px #919191, 1px 5px 1px #919191, 1px 6px 1px #919191,
      1px 7px 1px #919191, 1px 8px 1px #919191, 1px 9px 1px #919191,
      1px 10px 1px #919191, 1px 18px 6px rgba(16, 16, 16, 0.4),
      1px 22px 10px rgba(16, 16, 16, 0.2), 1px 25px 35px rgba(16, 16, 16, 0.2),
      1px 30px 60px rgba(16, 16, 16, 0.4);
  }
  .job-desc {
    text-transform: uppercase;
    font-family: verdana;
    font-size: 2vw;
    font-weight: 700;
    color: #f5f5f5;
    text-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191,
      1px 4px 1px #919191, 1px 5px 1px #919191, 1px 6px 1px #919191,
      1px 7px 1px #919191, 1px 8px 1px #919191, 1px 9px 1px #919191,
      1px 10px 1px #919191, 1px 18px 6px rgba(16, 16, 16, 0.4),
      1px 22px 10px rgba(16, 16, 16, 0.2), 1px 25px 35px rgba(16, 16, 16, 0.2),
      1px 30px 60px rgba(16, 16, 16, 0.4);
  }
}
@media (max-width: 500px) {
  .title-pos {
    position: relative;
    top: 25vh;
    right: 50%;
    animation-name: floating;
    animation-duration: 8s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    color: white;
  }
  .name-first {
    position: relative;
    height: 1.2em;
    text-transform: uppercase;
    font-family: verdana;
    font-size: 6.5vw;
    font-weight: 700;
    color: #f5f5f5;
    text-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191,
      1px 0px 1px #919191, 1px 5px 1px #919191, 1px 6px 1px #919191,
      1px 7px 1px #919191, 1px 8px 1px #919191, 1px 9px 1px #919191,
      1px 10px 1px #919191;
  }
  .name-last {
    text-transform: uppercase;
    font-family: verdana;
    font-size: 6.5vw;
    font-weight: 700;
    color: #f5f5f5;
    text-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191,
      1px 4px 1px #919191, 1px 5px 1px #919191, 1px 6px 1px #919191,
      1px 7px 1px #919191, 1px 8px 1px #919191, 1px 9px 1px #919191,
      1px 10px 1px #919191, 1px 18px 6px rgba(16, 16, 16, 0.4),
      1px 22px 10px rgba(16, 16, 16, 0.2), 1px 25px 35px rgba(16, 16, 16, 0.2),
      1px 30px 60px rgba(16, 16, 16, 0.4);
  }
  .job-desc {
    text-transform: uppercase;
    font-family: verdana;
    font-size: 2.25vw;
    font-weight: 700;
    color: #f5f5f5;
    text-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191,
      1px 4px 1px #919191, 1px 5px 1px #919191, 1px 6px 1px #919191,
      1px 7px 1px #919191, 1px 8px 1px #919191, 1px 9px 1px #919191,
      1px 10px 1px #919191, 1px 18px 6px rgba(16, 16, 16, 0.4),
      1px 22px 10px rgba(16, 16, 16, 0.2), 1px 25px 35px rgba(16, 16, 16, 0.2),
      1px 30px 60px rgba(16, 16, 16, 0.4);
  }
}
.name-wrapper {
  display: flex;
  flex-direction: column;
}

@keyframes floating {
  0% {
    transform: translate(52%, -46.5%);
  }
  25% {
    transform: translate(49.75%, -50%);
  }
  50% {
    transform: translate(50%, -46.5%);
  }
  75% {
    transform: translate(49.75%, -50%);
  }
  100% {
    transform: translate(52%, -46.5%);
  }
}
