// Much of the base CSS for BB-8 is credit to this Pen by Mike Dixon: https://codepen.io/mdixondesigns/pen/PPEJwz, which was the original inspiration for *this* Pen!

*,
*:before,
*:after {
  box-sizing: border-box;
}

p {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  color: #f5f5f5;
}

.config p {
  font-size: x-large;
}

.config {
  position: relative;
}

.control-wrap {
  margin-top: 10vh;
  float: left;
  margin: -15px 15px;
}

.logo img {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 70px;
}

// BB-8
$d: 150px;
.bb8 {
  position: absolute;
  margin-left: -$d/2;
  width: $d;
  z-index: -1;
  bottom: 15%;
  left: 0;
}
@media (min-width: 500px) {
  .bb8 {
    top: 60vh;
  }
}
@media (max-width: 500px) {
  .bb8 {
    top: 50vh;
  }
}
.antennas {
  position: absolute;

  transition: left 0.6s;

  left: 28%;

  &.right {
    left: 6%;
  }
}
.antenna {
  background: #e0d2be;
  position: absolute;
  width: 2px;

  &.short {
    height: 20px;

    top: -65px;
    left: 50px;
  }
  &.long {
    border-top: 6px solid #020204;
    border-bottom: 6px solid #020204;
    height: 36px;

    top: -80px;
    left: 56px;
  }
}
.head {
  background-color: #e9ebe2;
  border-radius: 90px 90px 25px 25px;
  -moz-border-radius: 90px 90px 25px 25px;
  -webkit-border-radius: 90px 90px 25px 25px;
  height: 63px;
  margin-left: -55px;
  overflow: hidden;
  position: absolute;
  width: 104px;
  z-index: 1;

  top: -56px;
  left: 53%;
  .stripe {
    position: absolute;
    width: 100%;
  }
  .stripe.one {
    background: #7699b7;
    height: 7px;
    opacity: 0.8;
    z-index: 1;

    top: 3px;
  }
  .stripe.two {
    background: #cd7640;
    height: 4px;

    top: 14px;
  }
  .stripe.three {
    background: #999;
    height: 4px;
    opacity: 0.5;

    bottom: 3px;
  }

  .stripe.detail {
    display: flex;
    width: 200px;
    bottom: 7px;
    left: -38%;
    transition: left 0.6s;
  }

  .stripe.detail.right {
    left: 0;
  }

  .detail {
    height: 7px;

    &.zero {
      background-color: #cd7640;
      width: 2%;
      margin-left: 3px;
    }

    &.one {
      background-color: #cd7640;
      width: 8%;
      margin-left: 3px;
    }

    &.two {
      background-color: #cd7640;
      width: 6%;
      margin-left: 5px;
    }

    &.three {
      background-color: #cd7640;
      width: 4%;
      margin-left: 45px;
      height: 5px;
      margin-top: 2px;
    }

    &.four {
      background-color: #cd7640;
      width: 10%;
      margin-left: 4px;
    }

    &.five {
      background-color: #cd7640;
      width: 2%;
      margin-left: 3px;
    }
  }

  .eyes {
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;

    transition: left 0.6s;

    left: 0;
  }
  .eyes.right {
    left: 36%;
  }
  .eye {
    border-radius: 50%;
    display: block;
    position: absolute;

    &.one {
      background: #020204;
      border: 4px solid lightgray;
      height: 30px;
      width: 30px;

      top: 12px;
      left: 12%;
    }
    &.one:after {
      background: #f5f5f5;
      border-radius: 50%;
      content: "";
      display: block;
      height: 3px;
      position: absolute;
      width: 3px;

      top: 4px;
      right: 4px;
    }

    &.two {
      background-color: lightgrey;
      border: 1px solid #020204;
      height: 16px;
      width: 16px;

      top: 30px;
      left: 40%;

      &:after {
        background: #020204;
        border-radius: 50%;
        content: "";
        display: block;
        height: 10px;
        position: absolute;
        width: 10px;

        top: 2px;
        left: 2px;
      }
    }
  }
}
.ball {
  background-color: #e9ebe2e5;
  border-radius: 50%;
  height: $d;
  overflow: hidden;
  position: relative;
  width: $d;
}
.lines {
  border: 2px solid #b19669;
  border-radius: 50%;
  height: 400px;
  opacity: 0.6;
  position: absolute;
  width: 400px;

  &.two {
    top: -10px;
    left: -250px;
  }
}
.ring {
  background: #cd7640;
  border-radius: 50%;
  height: 70px;
  margin-left: -35px;
  position: absolute;
  width: 70px;
  &:after {
    background-color: #d0d2ca;
    border-radius: 50%;
    content: "";
    display: block;
    height: 73%;
    margin-top: -36%;
    margin-left: -36%;
    position: absolute;
    width: 73%;

    top: 50%;
    left: 50%;
  }
  &.one {
    margin-left: -40px;
    height: 90px;
    width: 100px;

    top: 2%;
    left: 42%;
  }
  &.two {
    height: 40px;
    width: 80px;
    -ms-transform: rotate(50deg);
    -webkit-transform: rotate(50deg);
    transform: rotate(50deg);

    top: 65%;
    left: 8%;

    &:after {
      top: 100%;
    }
  }
  &.three {
    height: 37px;
    width: 80px;
    -ms-transform: rotate(-50deg);
    -webkit-transform: rotate(-50deg);
    transform: rotate(-50deg);

    top: 68%;
    left: 84%;

    &:after {
      top: 110%;
    }
  }
}
.shadow {
  background: #3a271c;
  box-shadow: 5px 0 50px #3a271c;
  border-radius: 50%;
  height: $d/6;
  opacity: 0.25;
  position: absolute;
  width: $d;
  z-index: -1;

  left: 10px;
  bottom: 165px;
}

.instructions p {
  position: fixed;
  bottom: 10px;
  width: 100%;
  text-align: center;
}
