@import url(https://fonts.googleapis.com/css?family=Cherry+Swash);

@media (min-width: 500px) {
  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2vh;
  }
  .flex-column {
    display: flex;
    margin-left: 10vw;
    flex-direction: column;
  }
  .wrapper > div {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .make-column {
    display: flex;
    flex-direction: column;
    margin-left: 12vw;
    align-items: center;
  }
  .wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 80vh;
  }
  .say-bet-pic {
    border: 2px solid;
    height: 85vh;
    margin-top: 24vh;
  }
  .test {
    color: rgb(177, 218, 255);
  }
  .summary-wrapper {
    display: flex;
    flex-direction: column;
    border: 2px solid;
    width: 45vw;
    min-height: 70vh;
    background-color: rgba(0, 0, 0, 0.56);
  }
  .full {
    border-image: linear-gradient(
        45deg,
        rgb(64, 205, 224),
        rgb(0, 0, 0)
      )
      1;
  }
  .full-img {
    border: 2.5px solid rgb(64, 205, 224);
  }
  .summary-wrapper > p {
    margin-left: 2.5vh;
    margin-right: 2.5vh;
    color: aliceblue;
    font-family: "segoe ui";
    text-align: justify;
    font-size: 2.3vh;
  }
  .summary-wrapper > div > p {
    margin-top: 0.7em;
    margin-bottom: 0.7em;
    margin-left: 2vh;
    margin-right: 2vh;
    color: aliceblue;
    font-family: "segoe ui";
    text-align: justify;
    font-size: 2.3vh;
  }

  .summary-wrapper > h4 {
    margin-top: 1em;
    margin-left: 2vh;
    color: aliceblue;
    font-size: 4.8vh;
    font-family: "Cherry Swash";
    padding-bottom: 1vh;
  }
  .title > h4 {
    margin-top: -2vh;
    color: rgb(202, 230, 255);
    font-size: 3vh;
  }
  .summary-wrapper > div {
    display: flex;
    justify-content: center;
  }

  .summary-wrapper > div > button {
    margin-top: 2vw;
    padding: 10px;
    border-radius: 5px;
    color: aliceblue;
    border: 2px solid rgb(177, 218, 255);
    box-sizing: border-box;
    font-family: "segoe ui";
    color: black;
    background-color: aliceblue;
    font-weight: bold;
    &:hover {
      background-color: rgb(99, 229, 186);
      transform: scale(1.2);
      transition-duration: 0.5s;
    }
  }
  @-moz-document url-prefix() {
    #push-up {
      margin-top: -3vh;
    }
    #button-wrapper {
      margin-top: -2vh;
    }
  }
}

@media (max-width: 500px) {
  .row-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 0.7em;
    margin-left: 2vh;
    margin-right: 2vh;
    color: aliceblue;
    font-family: "segoe ui";
    /* text-align: justify; */
    font-size: 4vw;
  }
  .summary-wrapper {
    border: 2px solid rgb(177, 218, 255);
    background-color: rgba(0, 0, 0, 0.895);
    margin: 4px;
    width: 90vw;
    height: 64vh;
    min-height: auto;
  }
  .summary-wrapper > h4 {
    margin-top: 1em;
    margin-left: 2vh;
    color: aliceblue;
    font-size: 3vh;
    font-family: "Cherry Swash";
  }

  .github-link {
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: 7vw;
  }
  .summary-wrapper > div > button {
    padding: 5px;
    color: aliceblue;
    border: 2px solid rgb(177, 218, 255);
    box-sizing: border-box;
    font-family: "segoe ui";
    color: black;
    background-color: aliceblue;
    font-weight: bold;
  }
  .summary-wrapper > p {
    margin-top: 0.7em;
    margin-left: 2vh;
    margin-right: 2vh;
    color: aliceblue;
    font-family: "segoe ui";
    text-align: justify;
    font-size: 4vw;
  }
  .button-br {
    color: aliceblue;
  }
  h1 {
    color: rgb(177, 218, 255);
  }
  .title {
    margin-top: -0.5em;
  }
  .title > h4 {
    color: rgba(177, 219, 255, 0.728);
    font-size: 4vw;
    padding-bottom: 1vh;
  }
  p.toggle {
    color: rgb(188, 224, 255);
    margin-top: 2.25vh;
  }
  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -15.5vh;
  }
  .say-bet-pic {
    border: 2px solid rgb(177, 218, 255);
    margin-top: 0.5vh;
    height: 65vh;
    width: 60vw;
  }
  .mine-pic {
    border: 2px solid rgb(177, 218, 255);
    margin-top: 0.5vh;
    height: 65vh;
    width: 70vw;
  }
  .todo-pic {
    border: 2px solid rgb(177, 218, 255);
    margin-top: 0.5vh;
    height: 65vh;
    width: 70vw;
  }
}
#button-wrapper {
  display: flex;
  flex-direction: row;
  margin-left: 7vw;
  margin-right: 7vw;
  justify-content: space-around;
}
